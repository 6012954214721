import React from "react"
import styled from "@emotion/styled"
import { Radar } from 'react-chartjs-2';
import { getMaker } from './datas/makerAll';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { mq } from "../utils/style";

const Wrapper = styled.div({
  [mq('mobile')] : {
    width: '100%',
    height: '100%',
  },
  [mq('pc')] : {
    width: '300px',
    height: '300px',
    float: 'right',
    paddingLeft: '10px',
  },
  // cursor: 'pointer',
  // width: '50px',
  // height: '50px',
  // position: 'fixed',
  // background: 'rgba(51,51,51,.5)',
  // bottom: '0',
  // right: '50px',
  // '.arrow-top': {
  //   marginLeft: '13px',
  //   marginTop: '19px',
  //   width: 0,
  //   height: 0,
  //   borderLeft: '12px solid transparent',
  //   borderRight: '12px solid transparent',
  //   borderBottom: '12px solid white',
  // }
})

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      labels: {
        font: {
          size: 20
        }
      },
    },
    labels: {
      fontColor: 'black',
    }
  },
  scales: {
    r: {
      pointLabels: {
        color: 'rgba(132, 99, 255, 1)',
        font: {
          size: 18,
          weight: 'bold'
        }
      },
      suggestedMin: 0,
      suggestedMax: 10
    }
  }
};

export default class RaderChart extends React.Component {
  constructor(props) {
    super(props);
    const makerInfo = getMaker(props.maker);
    const { cospa, design, support, popular, fname, stable } = makerInfo;
    this.data = {
      labels: ['コスパ', 'サポート', 'デザイン', '人気', '安定性'],
      datasets: [
        {
          label: fname,
          data: [cospa, support, design, popular, stable],
          backgroundColor: 'rgba(255, 99, 132, 0.7)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: '業界平均',
          data: [6, 6, 6, 5, 6],
          backgroundColor: 'rgba(200, 200, 200, 0.2)',
          borderColor: 'rgba(55, 255, 132, 1)',
          borderWidth: 1,
        },
      ],
    };
  }

  render () {
    return (
      <Wrapper className={this.props.className}>
        <Radar data={this.data} options={options} />
        {/* <AccentBox title="レーダーチャートの説明">
          <li><span className="bold">コストパフォーマンス</span>・・・価格の割にパソコンの基本スペックが高いかどうか</li>
          <li><span className="bold">サポート</span>・・・故障時等のサポートはスムーズさ、マニュアルやWebのQ/Aが整っており初心者にも優しいか。</li>
          <li><span className="bold">デザイン</span>・・・パソコン本体のデザインが洗練されているか、ブランド力があるか。</li>
          <li><span className="bold">人気</span>・・・ネットでの比較検討の後に選ばれるメーカーであるか。当サイト経由での売上から順位付け。</li>
          <li><span className="bold">安定性</span>・・・企業倒産などにより事業が継続できなくなる可能性はあるか。</li>
        </AccentBox> */}
      </Wrapper>
    );
  }
}